import imageAsset from './imageAsset'

export const fields = `
  model,
  id,
  dimensions,
  lifestyleImages[]{${imageAsset.fields}},
  imageOrientation,
  description,
  placeOfOrigin,
  designedBy,
  variants[]{
    ${imageAsset.fragment('image')},
    "material": material->title,
    leadTime,
    weight
  }
`

export const fragment = (name = 'productData') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
