import classnames from 'classnames'
import styles from './LogoWithProductsItem.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions, getImageBackgroundFromAsset } from '@/utils'
import MonoSlashModelText from '@/components/MonoSlashModelText/MonoSlashModelText'
import ContentMask from '@/components/ContentMask/ContentMask'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import { DOC_TYPES } from '@/data'
import Link from '@/components/Link/Link'

const IMAGE_ORIENTATION_BY_INDEX = {
  0: {
    type: 'square',
    imageConfig: {
      tablet: {
        width: 1100,
        height: 1100,
      },
      mobile: {
        width: 900,
        height: 900,
      },
      xs: {
        width: 750,
        height: 750,
      },
    },
  },
  1: {
    type: 'portrait',
    imageConfig: {
      tablet: {
        width: 1250,
        height: getCropHeightFromWidth('portrait', 1250),
      },
      mobile: {
        width: 1000,
        height: getCropHeightFromWidth('portrait', 1000),
      },
      xs: {
        width: 750,
        height: getCropHeightFromWidth('portrait', 750),
      },
    },
  },
  2: {
    type: 'wide',
    imageConfig: {
      tablet: {
        width: 1500,
        height: getCropHeightFromWidth('wide', 1500),
      },
      mobile: {
        width: 1100,
        height: getCropHeightFromWidth('wide', 1100),
      },
      xs: {
        width: 750,
        height: getCropHeightFromWidth('wide', 750),
      },
    },
  },
  3: {
    type: 'portrait',
    imageConfig: {
      tablet: {
        width: 600,
        height: getCropHeightFromWidth('portrait', 600),
      },
      mobile: {
        width: 1100,
        height: getCropHeightFromWidth('portrait', 1100),
      },
      xs: {
        width: 750,
        height: getCropHeightFromWidth('portrait', 750),
      },
    },
  },
}

const LogoWithProductsItem = ({ className, title, slug, index, productData }) => {
  const firstVariant = productData?.variants[0]
  const aspectRatio = `${IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.tablet.width}/${IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.tablet.height}`

  if (!firstVariant) return null

  return (
    <div
      className={classnames(styles.LogoWithProductsItem, className)}
      data-index={index}
      style={{ '--aspect-ratio': aspectRatio }}
    >
      <Link
        className={styles.link}
        link={{
          linkType: 'internal',
          link: {
            _id: 'anyString',
            _type: DOC_TYPES.PRODUCT,
            slug: slug.current,
          },
        }}
      >
        <ImageReveal
          backgroundColor={getImageBackgroundFromAsset(firstVariant.image)}
          className={styles.imageContainer}
          showContent={index === 0 || index === 1}
        >
          <SanityImage
            image={firstVariant.image}
            className={styles.image}
            priority
            breakpoints={{
              tablet: {
                width: IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.tablet.width,
                image: firstVariant.image,
                options: getCropOptions(productData.imageOrientation, IMAGE_ORIENTATION_BY_INDEX[index]?.type, {
                  height: IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.tablet.height,
                }),
              },
              mobile: {
                width: IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.mobile.width,
                image: firstVariant.image,
                options: getCropOptions(productData.imageOrientation, IMAGE_ORIENTATION_BY_INDEX[index]?.type, {
                  height: IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.mobile.height,
                }),
              },
              xs: {
                width: IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.xs.width,
                image: firstVariant.image,
                options: getCropOptions(productData.imageOrientation, IMAGE_ORIENTATION_BY_INDEX[index]?.type, {
                  height: IMAGE_ORIENTATION_BY_INDEX[index].imageConfig.xs.height,
                }),
              },
            }}
          />
        </ImageReveal>
        <div className={styles.textContainer}>
          <ContentMask
            animateInView
            innerClassName={styles.textContainerInner}
          >
            <MonoSlashModelText
              monoText={`00${index + 1}`}
              modelText={productData?.model}
            />
            <p
              className={styles.title}
              data-themed="color"
            >
              {title}
            </p>
          </ContentMask>
        </div>
      </Link>
    </div>
  )
}

LogoWithProductsItem.displayName = 'LogoWithProductsItem'

export default LogoWithProductsItem
