import classnames from 'classnames'
import styles from './Wipe.module.scss'
import useStore from '@/store'
import { useEffect, useRef } from 'react'
import { TRANSITION_DURATION } from '@/data'
import gsap from 'gsap'

const Wipe = ({ className }) => {
  const globalData = useStore(state => state.globalData)
  const setPageIsTransitioning = useStore(state => state.setPageIsTransitioning)
  const pageIsTransitioning = useStore(state => state.pageIsTransitioning)
  const drawerData = globalData?.navigation?.navigationDrawerContent
  const bgRef = useRef()
  const textLeftRef = useRef()
  const textRightRef = useRef()
  const allowPageTransitionsRef = useRef(false)

  const resetAnimation = () => {
    gsap.set(bgRef.current, {
      '--left-y': '100%',
      '--right-y': '100%',
      y: 0,
    })

    gsap.set([textLeftRef.current, textRightRef.current], {
      y: 40,
      autoAlpha: 0,
    })
  }

  const animateIn = callback => {
    if (!bgRef.current || !textLeftRef.current || !textRightRef.current) return

    gsap.killTweensOf([bgRef.current, textLeftRef.current, textRightRef.current])

    gsap.to(bgRef.current, {
      '--left-y': '0%',
      '--right-y': '0%',
      duration: TRANSITION_DURATION,
      ease: 'Power3.easeInOut',
    })

    gsap.to([textLeftRef.current, textRightRef.current], {
      y: 0,
      autoAlpha: 1,
      duration: TRANSITION_DURATION,
      ease: 'Power3.easeInOut',
      onComplete: () => {
        if (callback) callback()
      },
    })
  }

  const animateOut = callback => {
    if (!bgRef.current || !textLeftRef.current || !textRightRef.current) return

    gsap.killTweensOf([bgRef.current, textLeftRef.current, textRightRef.current])

    gsap.to(bgRef.current, {
      y: '-100%',
      duration: TRANSITION_DURATION,
      ease: 'Power3.easeInOut',
      onComplete: () => {
        if (callback) callback()
      },
    })

    gsap.to([textLeftRef.current, textRightRef.current], {
      y: -40,
      autoAlpha: 0,
      duration: TRANSITION_DURATION * 0.75,
      ease: 'Power3.easeInOut',
    })
  }

  useEffect(() => {
    setTimeout(() => {
      resetAnimation()
      allowPageTransitionsRef.current = true
    }, 500)
  }, [])

  useEffect(() => {
    if (!allowPageTransitionsRef.current || pageIsTransitioning === false) return

    animateIn(() => {
      animateOut(() => {
        setPageIsTransitioning(false)
        resetAnimation()
      })
    })
  }, [pageIsTransitioning, setPageIsTransitioning])

  if (!drawerData) return null

  return (
    <div className={classnames(styles.Wipe, className)}>
      <div
        className={styles.wipeBg}
        ref={bgRef}
      />
      <span className={styles.titleLeft}>
        <span
          className={styles.titleLeft__inner}
          ref={textLeftRef}
        >
          {drawerData?.titleType}
        </span>
      </span>
      <span className={styles.titleRight}>
        <span
          className={styles.titleRight__inner}
          ref={textRightRef}
        >
          {drawerData?.titleLocation}
        </span>
      </span>
    </div>
  )
}

Wipe.displayName = 'Wipe'

export default Wipe
