import classnames from 'classnames'
import styles from './LogoWithProducts.module.scss'
import LogoSvg from '@/components/_svg/Logo'
import LogoWithProductsItem from './LogoWithProductsItem/LogoWithProductsItem'
import useStore from '@/store'

export const LOGO_CONTAINER_ID = 'hugeLogo'

const LogoWithProducts = ({ className, products }) => {
  const loaderAnimationComplete = useStore(state => state.loaderAnimationComplete)

  if (!products?.length) return null

  return (
    <div className={classnames(styles.LogoWithProducts, className)}>
      <div
        id={LOGO_CONTAINER_ID}
        className={classnames(styles.logoContainer, { [styles.isVisible]: loaderAnimationComplete })}
      >
        <LogoSvg />
      </div>
      <div className={styles.productsContainer}>
        {products.map((product, i) => (
          <LogoWithProductsItem
            {...product}
            index={i}
            key={i}
          />
        ))}
      </div>
    </div>
  )
}

LogoWithProducts.displayName = 'LogoWithProducts'

export default LogoWithProducts
