import classnames from 'classnames'
import styles from './NextProduct.module.scss'
import { DOC_TYPES } from '@/data'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions } from '@/utils'
import Link from '@/components/Link/Link'

const NextProduct = ({ className, product }) => {
  const firstVariant = product?.productData?.variants[0]

  if (!product || !firstVariant) return null

  return (
    <div
      className={classnames(styles.NextProduct, className)}
      data-themed="background-color"
    >
      <Link
        className={styles.link}
        link={{
          linkType: 'internal',
          link: {
            _id: 'anyString',
            _type: DOC_TYPES.PRODUCT,
            slug: product.slug.current,
          },
        }}
      >
        <p
          className={styles.textLeft}
          data-themed="color"
        >
          Next
        </p>
        <p
          className={styles.textRight}
          data-themed="color"
        >
          Item
        </p>
        <div className={styles.imageContainer}>
          <SanityImage
            className={styles.productImage}
            image={firstVariant?.image}
            priority
            breakpoints={{
              tablet: {
                width: 700,
                image: firstVariant?.image,
                options: getCropOptions(product.productData.imageOrientation, 'portrait', {
                  height: getCropHeightFromWidth('portrait', 700),
                }),
              },
              xs: {
                width: 300,
                image: firstVariant?.image,
                options: getCropOptions(product.productData.imageOrientation, 'portrait', {
                  height: getCropHeightFromWidth('portrait', 300),
                }),
              },
            }}
          />
        </div>
      </Link>
    </div>
  )
}

NextProduct.displayName = 'NextProduct'

export default NextProduct
