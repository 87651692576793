import { pageMetadataFields } from './_shared'
import sections from './sections'

export const getFields = () => {
  return `
    ${pageMetadataFields},
    ${sections.fragment('sections')}
  `
}

export const getSections = () => {
  return `
    ${sections.fragment('sections')}
  `
}

const exported = {
  getFields,
  getSections,
}

export default exported
