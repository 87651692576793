import { useEffect, useRef } from 'react'
import classnames from 'classnames'
import gsap from 'gsap'
import useInView from '@/hooks/use-in-view'
import useIsReducedMotion from '@/hooks/use-is-reduced-motion'
import styles from './ImageReveal.module.scss'

const ImageReveal = ({ className, children, direction = 'FROM_TOP', delay = 0, id, showContent, backgroundColor }) => {
  const { isInView, setElementToObserve } = useInView()
  const containerRef = useRef(null > null)
  const { isReducedMotion } = useIsReducedMotion()

  let backgroundColorCss = {}
  if (backgroundColor) {
    backgroundColorCss = {
      '--background-color': backgroundColor,
    }
  }

  useEffect(() => {
    if (!containerRef.current || !isInView) return

    const ctx = gsap.context(() => {
      gsap.killTweensOf(containerRef.current)

      const duration = 0.6
      const ease = 'Power3.easeInOut'

      gsap.to(`.${styles.imageContainer}`, {
        autoAlpha: 1,
        duration,
        ease,
        delay: delay || 0.1,
      })
    }, containerRef.current)

    return () => {
      ctx.revert()
    }
  }, [isInView, direction, delay, isReducedMotion])

  return (
    <div
      ref={ref => {
        setElementToObserve(ref)
        containerRef.current = ref
      }}
      id={id}
      className={classnames(
        styles.ImageReveal,
        className,
        { [styles[direction]]: direction },
        { [styles.showContent]: showContent },
        { [styles.hasBackgroundColor]: backgroundColor },
      )}
      style={backgroundColorCss}
    >
      <div className={styles.backgroundColor} />
      <div className={styles.imageContainer}>{children}</div>
    </div>
  )
}

ImageReveal.displayName = 'ImageReveal'

export default ImageReveal
