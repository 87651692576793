import cmsSettings from '../cmsSettings'
import link from '../link'

const richTextFields = `
	_key,
	_type,
	children,
	level,
	listItem,
	style,
	markDefs[] {
		_type == "linkNoLabel" => {
			${link.fields}
		},
		_key,
		_type,
	}
`

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  description[] {
    ${richTextFields}
  },
  smallerDescription[] {
    ${richTextFields}
  },
  links[]{${link.fields}}
`

export const fragment = (name = 'multiParagraphWithLinks') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
