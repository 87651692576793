import * as fragments from './sections/index'

const ALL_SECTIONS = [
  /* INJECT_TYPE */
  'productsInfiniteCarousel',
  'twoImagesAndText',
  'productHero',
  'productGrid',
  'lithLogo',
  'resizingSlider',
  'standaloneMedia',
  'multiParagraphWithLinks',
  'linkBlock',
  'logoWithProducts',
  'testComponent',
  'fourOhFour',
]

const fragmentsCopy = { ...fragments }

export const getFields = () => {
  const sectionsToQuery = ALL_SECTIONS

  return sectionsToQuery
    .map(typeName => {
      if (fragmentsCopy[typeName]) {
        return `_type == "${typeName}" => { ${fragmentsCopy[typeName].fields} }\n`
      }
    })
    .join(',')
}

/* eslint-disable */
export const fragment = (name = 'sections') => {
  return `${name}[] -> {
      _id,
      _ref,
      "draft": null,
      section[] {
        ${getFields()}
      }
    }`
}
/* eslint-enable */

const exported = {
  fragment,
  getFields,
}

export default exported
