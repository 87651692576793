import classnames from 'classnames'
import styles from './ProductGridTextImageContainer.module.scss'
import useStore from '@/store'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions } from '@/utils'

const ProductGridTextImageContainer = ({ className }) => {
  const productGridActiveProductIndex = useStore(state => state.productGridActiveProductIndex)
  const productGridProducts = useStore(state => state.productGridProducts)

  if (!productGridProducts?.length || typeof productGridActiveProductIndex !== 'number') return null

  return (
    <div className={classnames(styles.ProductGridTextImageContainer, className)}>
      <div className={styles.imageContainer}>
        {productGridProducts.map((product, i) => {
          const variant = product?.productData?.variants[0]

          if (!variant) return

          return (
            <SanityImage
              disableLoadedOpacity
              key={`${i}_${product.title}`}
              className={classnames(styles.image, { [styles.isActive]: i === productGridActiveProductIndex })}
              image={variant.image}
              priority
              breakpoints={{
                tablet: {
                  width: 900,
                  image: variant.image,
                  options: getCropOptions(product?.productData?.imageOrientation, 'portrait', {
                    height: getCropHeightFromWidth('portrait', 900),
                  }),
                },
                xs: {
                  width: 500,
                  image: variant.image,
                  options: getCropOptions(product?.productData?.imageOrientation, 'portrait', {
                    height: getCropHeightFromWidth('portrait', 500),
                  }),
                },
              }}
            />
          )
        })}
      </div>
    </div>
  )
}

ProductGridTextImageContainer.displayName = 'ProductGridTextImageContainer'

export default ProductGridTextImageContainer
