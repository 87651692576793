import classnames from 'classnames'
import styles from './MonoSlashModelText.module.scss'

const MonoSlashModelText = ({ className, monoText, modelText }) => {
  if (!monoText && !modelText) return null

  return (
    <p className={classnames(styles.MonoSlashModelText, className)}>
      {monoText && (
        <span
          className={styles.index}
          data-themed="color"
        >
          {monoText}
        </span>
      )}
      {monoText && modelText && (
        <span
          className={styles.indexSlash}
          data-themed="color"
        >
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
        </span>
      )}
      {modelText && (
        <span
          className={styles.model}
          data-themed="color"
        >
          {modelText}
        </span>
      )}
    </p>
  )
}

MonoSlashModelText.displayName = 'MonoSlashModelText'

export default MonoSlashModelText
