import cmsSettings from '../cmsSettings'
import imageAsset from '../imageAsset'
import fileAsset from '../fileAsset'
import link from '../link'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  mediaType,
  ${imageAsset.fragment('image')},
  ${fileAsset.fragment('videoLoopDesktop')},
  ${fileAsset.fragment('videoLoopMobile')},
  titleMono,
  titleModelNumber,
  topRightTitle,
  ${link.fragment('link')}
`

export const fragment = (name = 'standaloneMedia') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
