import { createClient } from 'next-sanity'

import { page, siteSettings } from '@/data/sanity/fragments'
import { pageMetadataFields } from '@/data/sanity/fragments/_shared'
import { formatPageSections, formatSiteSettingsResponse, mergeSiteSettings } from '@/utils'

const perspective = process.env.SANITY_PREVIEW_TOKEN ? 'previewDrafts' : 'published'

const SANITY_CONFIG = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: process.env.SANITY_PREVIEW_TOKEN === undefined ? true : false,
  token: process.env.SANITY_PREVIEW_TOKEN,
  perspective: perspective,
}

export const client = createClient(SANITY_CONFIG)

export const getSiteSettings = async isPreview => {
  /* eslint-disable */
  const siteSettingsData = await client.fetch(
    `
      *[(
        _type == "siteSettingsMetadata" ||
        _type == "navigation" 
        )] {
        ${siteSettings.fields}
      }
  `,
    { isPreview: Boolean(isPreview) },
  )

  /* eslint-enable */

  if (!siteSettingsData?.length) return null

  const formatted = formatSiteSettingsResponse(siteSettingsData)

  return formatted
}

export const getPage = async (slug, type = 'page', isPreview) => {
  const response = (
    await client.fetch(
      `
      *[_type == "${type}" && slug.current == "${slug}"] {
        ${page.getFields(true)}
      }
  `,
      { isPreview: Boolean(isPreview) },
    )
  )[0]

  const siteSettings = await getSiteSettings(isPreview)

  if (!response || !siteSettings) return null

  const merged = mergeSiteSettings(response, siteSettings)
  const formatted = formatPageSections(merged)

  return formatted
}

export const getPageSections = async (slug, type = 'page', isPreview) => {
  try {
    const response = (
      await client.fetch(
        `
      *[_type == "${type}" && slug.current == "${slug}"] {
        ${page.getSections(false)}
      } 
    `,
        { isPreview: Boolean(isPreview) },
      )
    )[0]

    return response

    // return formatted
  } catch (err) {
    // eslint-disable-next-line
    console.log({ err })
  }
}

export const getAllPages = async (type = 'page', isPreview) => {
  const pages = await client.fetch(
    `
    *[_type == "${type}"] {
      ${pageMetadataFields}
    }
  `,
    { isPreview: Boolean(isPreview) },
  )

  if (!pages.length) return null

  return pages
}
