import link from './link'
import image from './imageAsset'
import { pageMetadataFields } from './_shared'

export const fields = `
  _type,
  title,
  timeZone,
  location,
  mainLinks[]{${link.fields}},
  navigationDrawerContent {
    contactEmail,
    titleDescription,
    titleType,
    titleLocation,
    copyright,
    description,
    lists[]{
      title,
      items[]
    },
    socialLinks[]{${link.fields}},
    ${image.fragment('image')},
    materialsListProduct->{${pageMetadataFields}}
  },
  preloader {
    products[]->{
      ${pageMetadataFields}
    }
  }
`

export const fragment = (name = 'navigation') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
