import cmsSettings from '../cmsSettings'
import link from '../link'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  title,
  ${link.fragment('link')},
  showProductCount,
  "productCount": count(*[_type == "product"]) 
`

export const fragment = (name = 'linkBlock') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
