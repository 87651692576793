import styles from './ProductZoomView.module.scss'
import useStore from '@/store'
import { useCallback, useEffect, useRef, useState } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import gsap from 'gsap'
import { getCropHeightFromWidth, getCropOptions, lerp } from '@/utils'
import SanityImage from '@/components/SanityImage/SanityImage'

// https://codepen.io/tuan-anh-the-decoder/pen/BajGOGb

const OUT_DURATION = 0.6

const ProductZoomView = () => {
  const [windowAspect, setWindowAspect] = useState(null)
  const resizeKey = useWindowResize()
  const zoomRef = useRef()
  const zoomPosCurrent = useRef({ x: 0, y: 0 })
  const zoomPosTarget = useRef({ x: 0, y: 0 })
  const hoverBoxPosCurrent = useRef({ x: 0, y: 0 })
  const hoverBoxPosTarget = useRef({ x: 0, y: 0 })
  const rafRef = useRef()
  const productZoomImage = useStore(state => state.productZoomImage)
  const setProductZoomImage = useStore(state => state.setProductZoomImage)
  const boxRef = useRef()
  const hoverBoxRef = useRef()
  const containerRef = useRef()
  const debounceAnimationRef = useRef()
  const [render, setRender] = useState(false)
  const [cachedZoomImage, setCachedZoomImage] = useState(null)
  const zoomImageDebounce = useRef()

  useEffect(() => {
    if (zoomImageDebounce.current) {
      clearTimeout(zoomImageDebounce.current)
    }

    const duration = !productZoomImage ? OUT_DURATION * 1000 : 0

    zoomImageDebounce.current = setTimeout(() => {
      setCachedZoomImage(productZoomImage)
    }, duration)
  }, [productZoomImage])

  const initRaf = useCallback(() => {
    rafRef.current = requestAnimationFrame(initRaf)

    zoomPosCurrent.current = {
      x: lerp(zoomPosCurrent.current.x, zoomPosTarget.current.x, 0.1),
      y: lerp(zoomPosCurrent.current.y, zoomPosTarget.current.y, 0.1),
    }

    if (zoomRef.current) {
      gsap.set(zoomRef.current, {
        x: `${-zoomPosCurrent.current.x}%`,
        y: `${-zoomPosCurrent.current.y}%`,
      })
    }

    hoverBoxPosCurrent.current = {
      x: lerp(hoverBoxPosCurrent.current.x, hoverBoxPosTarget.current.x, 0.2),
      y: lerp(hoverBoxPosCurrent.current.y, hoverBoxPosTarget.current.y, 0.2),
    }

    if (hoverBoxRef.current) {
      gsap.set(hoverBoxRef.current, {
        x: hoverBoxPosCurrent.current.x,
        y: hoverBoxPosCurrent.current.y,
      })
    }
  }, [])

  const killRaf = () => {
    zoomPosTarget.current = {
      x: 0,
      y: 0,
    }

    zoomPosCurrent.current = {
      x: 0,
      y: 0,
    }

    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
      rafRef.current = null
    }
  }

  useEffect(() => {
    killRaf()

    if (!cachedZoomImage) return

    initRaf()

    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current)
    }
  }, [initRaf, cachedZoomImage])

  const calculateHoverBox = ({ x, y }) => {
    if (!zoomRef.current || !hoverBoxRef.current) {
      return
    }

    const enlargedDimensions = {
      width: zoomRef.current?.offsetWidth,
      height: zoomRef.current?.offsetHeight,
    }

    const scale = enlargedDimensions.width / (window.innerWidth * 0.5)

    const width = (window.innerWidth * 0.5) / scale
    const height = window.innerHeight / scale

    x = gsap.utils.clamp(0, window.innerWidth * 0.5 - width, window.innerWidth * 0.5 * x - width * 0.5)
    y = gsap.utils.clamp(0, window.innerHeight - height, window.innerHeight * y - height * 0.5)

    gsap.set(hoverBoxRef.current, {
      width,
      height,
    })

    hoverBoxPosTarget.current = {
      x,
      y,
    }
  }

  const handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    let x = ((e.clientX - left) / width) * 100
    let y = ((e.clientY - top) / height) * 100

    calculateHoverBox({ x: (e.clientX - left) / width, y: (e.clientY - top) / height })

    x = x - 50
    y = y - 50

    const quarterWindowWidth = window.innerWidth * 0.25
    const quarterWindowHeight = window.innerHeight * 0.5

    const percentOfZoomWidth = quarterWindowWidth / zoomRef.current.offsetWidth
    const percentOfZoomHeight = quarterWindowHeight / zoomRef.current.offsetHeight

    x = gsap.utils.clamp(-50 + percentOfZoomWidth * 100, 50 - percentOfZoomWidth * 100, x)
    y = gsap.utils.clamp(-50 + percentOfZoomHeight * 100, 50 - percentOfZoomHeight * 100, y)

    zoomPosTarget.current = {
      x,
      y,
    }
  }

  useEffect(() => {
    setWindowAspect(`${window.innerWidth / 2}/${window.innerHeight}`)
  }, [resizeKey])

  useEffect(() => {
    if (debounceAnimationRef.current) {
      clearTimeout(debounceAnimationRef.current)
    }

    const isActive = productZoomImage

    if (isActive) {
      setRender(true)
    }

    debounceAnimationRef.current = setTimeout(() => {
      gsap.killTweensOf(containerRef.current)
      gsap.to(containerRef.current, {
        '--left-y': isActive ? '100%' : '0%',
        '--right-y': isActive ? '100%' : '0%',
        duration: isActive ? OUT_DURATION * 2 : OUT_DURATION,
        ease: 'Power4.easeInOut',
        onComplete: () => {
          if (!isActive) {
            setRender(false)
          }
        },
      })
    }, 10)
  }, [productZoomImage])

  if (!render) return null

  return (
    <div
      className={styles.ProductZoomView}
      style={{ '--window-aspect': windowAspect }}
      ref={containerRef}
    >
      <div
        className={styles.leftSide}
        onMouseMove={handleMouseMove}
      >
        <div
          className={styles.box}
          ref={boxRef}
        />
        {cachedZoomImage && (
          <SanityImage
            className={styles.img}
            image={cachedZoomImage.image}
            breakpoints={{
              tablet: {
                width: 1200,
                image: cachedZoomImage.image,
                options: getCropOptions(cachedZoomImage.imageOrientation, 'portrait', {
                  height: getCropHeightFromWidth('portrait', 1200),
                }),
              },
              xs: {
                width: 1200,
                image: cachedZoomImage.image,
                options: getCropOptions(cachedZoomImage.imageOrientation, 'portrait', {
                  height: getCropHeightFromWidth('portrait', 1200),
                }),
              },
            }}
          />
        )}

        <div
          className={styles.hoverBox}
          ref={hoverBoxRef}
        />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.zoomImageContainer}>
          <div
            className={styles.zoomImageContainer__inner}
            ref={zoomRef}
          >
            {cachedZoomImage && (
              <SanityImage
                className={styles.zoomImageContainer__img}
                image={cachedZoomImage.image}
                breakpoints={{
                  tablet: {
                    width: 2500,
                    image: cachedZoomImage.image,
                    options: getCropOptions(cachedZoomImage.imageOrientation, 'portrait', {
                      height: getCropHeightFromWidth('portrait', 2500),
                    }),
                  },
                  xs: {
                    width: 1200,
                    image: cachedZoomImage.image,
                    options: getCropOptions(cachedZoomImage.imageOrientation, 'portrait', {
                      height: getCropHeightFromWidth('portrait', 1200),
                    }),
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
      <button
        className={styles.close}
        onClick={() => {
          setProductZoomImage(null)
        }}
      >
        Close
      </button>
    </div>
  )
}

ProductZoomView.displayName = 'ProductZoomView'

export default ProductZoomView
