export const fields = `
  isHidden,
  cmsTitle,
  mobileBottomSpacingDecreased,
  disableOnMobile
`

export const fragment = (name = 'cmsSettings') => `${name}{ ${fields} }`

export default fragment
