import metadata from './metadata'
import navigation from './navigation'

export const fields = `
  _type == "siteSettingsMetadata" => {  ${metadata.fields} },
  _type == "navigation" => { ${navigation.fields} }
`

export const fragment = (name = 'siteSettings') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
