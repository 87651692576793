import fileAsset from './fileAsset'
import imageAsset from './imageAsset'
import metadata from './metadata'
import productData from './productData'

export const pageMetadataFields = `
  _type,
  _createdAt,
  _updatedAt,
  title,
  slug,
  ${metadata.fragment()},
  _type == "page" => {
    infiniteScrollingEnabled,
    hasFooter
  },
  _type == "product" => {
    "infiniteScrollingEnabled": false,
    "hasFooter": true,
    ${productData.fragment('productData')},
    "nextItem": productData.nextProduct->{title, slug, ${productData.fragment('productData')}}
  }
`

export const textAndImagesFields = `
  type,
  ${imageAsset.fragment('image')},
  ${fileAsset.fragment('videoLoopDesktop')},
  ${fileAsset.fragment('videoLoopMobile')}
`
