// Slugs / Paths
export const HOME_SLUG = 'home'
export const FOUR_OH_FOUR_SLUG = '404'
export const PRODUCTS_SLUG = 'products'

// Doc Types
export const DOC_TYPES = {
  PAGE: 'page',
  PRODUCT: 'product',
}

// Transitions
export const TRANSITION_DURATION = 1.2
// export const TRANSITION_DURATION = 40.2
export const TRANSITION_DURATION_MS = TRANSITION_DURATION * 1000

// Product Grid Views
export const PRODUCT_GRID_VIEW_TYPES = {
  DEFAULT: 'DEFAULT',
  COLUMN: 'COLUMN',
  SMALL: 'SMALL',
  TEXT: 'TEXT',
}
