import classnames from 'classnames'
import styles from './FixedProductGridHeader.module.scss'
import useStore from '@/store'
import useWindowResize from '@/hooks/use-window-resize'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import { useContext, useEffect, useRef } from 'react'
import { getIsMobile } from '@/hooks/use-breakpoint'
import { ScrollContext } from '@/context/Scroll'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

const FixedProductGridHeader = ({ className }) => {
  const productGridHeaderContent = useStore(state => state.productGridHeaderContent)
  const titleRef = useRef()
  const countRef = useRef()
  const scrollTriggerRef = useRef()
  const scrollTriggerBackRef = useRef()
  const timelineRef = useRef(null)
  const timelineBackRef = useRef(null)
  const resizeKey = useWindowResize()
  const backToTopButtonRef = useRef()
  const { scroll } = useContext(ScrollContext)
  const loaderAnimationComplete = useStore(state => state.loaderAnimationComplete)

  useEffect(() => {
    if (!titleRef.current || !countRef.current || !backToTopButtonRef.current) return

    if (scrollTriggerRef.current) {
      scrollTriggerRef.current.kill()
    }

    if (scrollTriggerBackRef.current) {
      scrollTriggerBackRef.current.kill()
    }

    if (timelineRef.current) {
      timelineRef.current.kill()
    }

    if (timelineBackRef.current) {
      timelineBackRef.current.kill()
    }

    timelineRef.current = gsap.timeline()

    const scale = getIsMobile(window.innerWidth) ? 0.9 : 0.6

    timelineRef.current.fromTo(
      [titleRef.current, countRef.current],
      {
        scale: 1,
        y: 0,
      },
      {
        scale,
        y: '-50%',
      },
    )

    scrollTriggerRef.current = new ScrollTrigger({
      trigger: '#main',
      start: 0,
      end: `+=${window.innerHeight}px`,
      animation: timelineRef.current,
      scrub: true,
    })

    timelineBackRef.current = gsap.timeline()

    timelineBackRef.current.fromTo(
      backToTopButtonRef.current,
      {
        autoAlpha: 0,
        pointerEvents: 'none',
      },
      {
        autoAlpha: 1,
        pointerEvents: 'all',
      },
    )

    const offset = window.innerHeight * 1

    scrollTriggerBackRef.current = new ScrollTrigger({
      trigger: '#main',
      start: offset,
      end: `+=${window.innerHeight * 0.5}px`,
      animation: timelineBackRef.current,
      scrub: true,
    })
  }, [resizeKey, productGridHeaderContent])

  useEffect(() => {
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill()
      }

      if (timelineBackRef.current) {
        timelineBackRef.current.kill()
      }
    }
  }, [])

  if (!productGridHeaderContent.title) {
    return null
  }

  return (
    <>
      <button
        className={styles.backToTopButton}
        ref={backToTopButtonRef}
        onClick={() => {
          scroll?.scrollTo(0, {
            duration: 0.8,
            lock: true,
          })
        }}
      >
        Back to Top
      </button>
      <div
        className={classnames(styles.ProductGridHeader, className, { [styles.allowVisual]: loaderAnimationComplete })}
      >
        <div className={styles.inner}>
          <h1
            id="fixedTitle"
            className={styles.title}
            data-themed="color"
            ref={titleRef}
          >
            {productGridHeaderContent.title}
          </h1>
          <p
            className={styles.count}
            data-themed="color"
            ref={countRef}
          >
            {productGridHeaderContent.count}
          </p>
        </div>
      </div>
    </>
  )
}

FixedProductGridHeader.displayName = 'FixedProductGridHeader'

export default FixedProductGridHeader
