const paletteItemFields = `
		background,
		foreground,
		population,
		title
`

// const paletteFields = `
// 	darkMuted { ${paletteItemFields} },
// 	darkVibrant { ${paletteItemFields} },
// 	dominant { ${paletteItemFields} },
// 	lightMuted { ${paletteItemFields} },
// 	lightVibrant { ${paletteItemFields} },
// 	muted { ${paletteItemFields} },
// 	vibrant { ${paletteItemFields} }
// `

export const fields = `
	_id,
	_key,
	alt,
	preload,
	_type,
	asset->{
		_id,
		_key,
		_type,
		alt,
		extension,
		metadata {
			palette {
				darkVibrant { ${paletteItemFields} }
			},
			dimensions {
				aspectRatio,
				height,
				width
			}
		},
		originalFilename,
		size,
		url
	}
`

// metadata->lqip,

export const fragment = name => {
  return `${name}{
		${fields}
	}`
}

const exports = { fields, fragment }

export default exports
