const LogoSvg = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1475 374"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.1699 0.0761719L0 373.68H124.988L110.478 158.575H155.678L139.061 373.68H255.19L240.111 158.575H286.659L269.214 373.68H398.632L359.131 0.076212H304.398H299.514H229H223.586H177.825H167.922L99.7868 0.0761719H40.1699Z"
        fill="currentColor"
        data-character={1}
        data-themed="fill, color"
      />
      <path
        d="M432.649 90.3435C433.945 18.1296 489.718 0.0761719 515.093 0.0761719H661.187C723.384 0.0761719 742.552 60.2544 742.012 90.3435V264.653C742.012 355.542 678.946 375.152 656.811 373.596H508.132C466.667 373.596 434.269 300.967 432.649 264.653C432.109 236.639 431.353 162.557 432.649 90.3435Z"
        fill="currentColor"
        data-character={2}
        data-themed="fill, color"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1084.17 0.0761719H789.589V158.575H789.589V373.673H937.989V158.575H982.292V373.673H1117.4V158.568H1084.17V0.0761719Z"
        fill="currentColor"
        data-character={3}
        data-themed="fill, color"
      />
      <path
        d="M1165.63 90.3435C1166.92 18.1296 1222.69 0.0761719 1248.07 0.0761719H1394.16C1456.36 0.0761719 1475.53 60.2544 1474.99 90.3435V264.653C1474.99 355.542 1411.92 375.152 1389.79 373.596H1241.11C1199.64 373.596 1167.25 300.967 1165.63 264.653C1165.09 236.639 1164.33 162.557 1165.63 90.3435Z"
        fill="currentColor"
        data-character={4}
        data-themed="fill, color"
      />
    </svg>
  )
}

LogoSvg.displayName = 'LogoSvg'

export default LogoSvg
