export const fields = `
	_type,
	alt,
	asset->
`

export const fragment = (name = 'file', extraFields = null) => `${name}{
	${extraFields !== null ? `${extraFields},` : ''}
	${fields}
}`

const exports = { fields, fragment }

export default exports
