import NextHead from 'next/head'
import { useRouter } from 'next/router'

import { getImageUrl } from '@/utils'

const FAVICON_SIZES = [16, 32, 96, 180, 512]
const APPLE_TOUCH_SIZES = [57, 60, 72, 76, 114, 120, 144, 152, 180, 192]

function Head({ title, description, keywords, shareImage, favicon, robots }) {
  const router = useRouter()
  const url = `${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`

  return (
    <NextHead>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5, shrink-to-fit=no"
      />

      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />
      {keywords && (
        <meta
          name="keywords"
          content={keywords}
        />
      )}

      {!robots && (
        <meta
          name="robots"
          content="noindex, nofollow"
        />
      )}

      {/* <link
        rel="manifest"
        href="/manifest.json"
      /> */}

      {/* Favicon */}
      {favicon &&
        FAVICON_SIZES.map((size, i) => (
          <link
            key={`favicon_${i}`}
            rel="icon"
            type="image/png"
            href={getImageUrl(favicon, { width: size, height: size }) || undefined}
            sizes={`${size}x${size}`}
          />
        ))}

      {/* APPLE TOUCH ICON */}
      {favicon &&
        APPLE_TOUCH_SIZES.map((size, i) => (
          <link
            key={`apple_touch_${i}`}
            rel="apple-touch-icon"
            href={getImageUrl(favicon, { width: size, height: size }) || undefined}
            sizes={`${size}x${size}`}
          />
        ))}

      {/* Share meta tags: OG */}
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:url"
        content={url}
      />
      <meta
        property="og:site_name"
        content={title}
      />
      <meta
        property="og:image:width"
        content="1200"
      />
      <meta
        property="og:image:height"
        content="630"
      />
      {shareImage && (
        <meta
          property="og:image"
          content={getImageUrl(shareImage, { width: 1200, height: 630, fit: 'clip' }) || undefined}
        />
      )}

      {/* Share meta tags: OG */}
      <meta
        name="twitter:card"
        content="summary"
      />
      {shareImage && (
        <meta
          name="twitter:image"
          content={getImageUrl(shareImage, { width: 800, height: 418, fit: 'clip' }) || undefined}
        />
      )}
      <meta
        name="twitter:title"
        content={title}
      />
      <meta
        name="twitter:description"
        content={description}
      />

      <link
        rel="canonical"
        href={url}
      />
    </NextHead>
  )
}

export default Head
