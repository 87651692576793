const ArrowRight = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0V3.6H20.862L0 24.462L2.538 27L23.4 6.138V18H27V0H9Z"
        fill="currentColor"
        data-themed="color, fill"
      />
    </svg>
  )
}

export default ArrowRight
