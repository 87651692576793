import cmsSettings from '../cmsSettings'
import { pageMetadataFields } from '../_shared'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  title,
  products[]->{${pageMetadataFields}}
`

export const fragment = (name = 'productGrid') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
