import cmsSettings from '../cmsSettings'
import { imageAsset } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  title,
  ${imageAsset.fragment('smallImage')},
  ${imageAsset.fragment('largeImage')}
`

export const fragment = (name = 'twoImagesAndText') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
